import PropTypes from 'prop-types';
import React from 'react';

import { ClockIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { YEAR } from 'constants/math';

import getDeadline from './helpers/getDeadline';
import getTimeLeft from './helpers/getTimeLeft';

// Displays the time left for an opportunity, can be expired as well (red background in this case) and without the clock icon
const OpportunityTimeLeft = ({
  deadline = null,
  expired = false,
  standalone = false,
  wrapperClassName = 'mt-px',
  testId = '',
}) => {
  let timeLeft = 'Ongoing';

  const hasDeadline = deadline !== null;
  if (hasDeadline) {
    const [diff, units] = getDeadline(deadline);

    // ongoing opportunities are marked with a deadline several hundred years in the future
    if (diff < 100 * YEAR) {
      timeLeft = getTimeLeft({
        diff,
        units,
        dateUtc: new Date(deadline),
        expired,
      });
    }
  }

  return (
    <div
      className={classNames(
        wrapperClassName,
        {
          'items-end lg:items-start w-full lg:w-40': !standalone,
          'w-fit': standalone,
        },
        'flex flex-col max-w-prose'
      )}
      data-test-id={`${testId}-deadline`}
    >
      <div
        className={classNames(
          'flex items-center py-1 px-2 lg:pr-3 text-xs space-x-2 bg-opacity-40 truncate whitespace-nowrap font-semibold',
          {
            'mr-4 rounded-t-md lg:mr-0 lg:rounded-l-none lg:rounded-r-md lg:mt-2 ':
              !standalone,
            'rounded-md': standalone,
            'bg-red-200 text-red-400': expired,
            'bg-gray-200 text-gray-400': !expired || !hasDeadline,
          }
        )}
        data-intercom-id="opportunity-deadline"
      >
        {hasDeadline && <ClockIcon className="w-4" />}
        <p>
          <span>{timeLeft}</span>{' '}
        </p>
      </div>
    </div>
  );
};

OpportunityTimeLeft.propTypes = {
  deadline: PropTypes.oneOfType([PropTypes.object.isRequired]),
  standalone: PropTypes.bool,
  expired: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  testId: PropTypes.string,
};

export default OpportunityTimeLeft;
