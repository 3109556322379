import { useMatch } from 'react-router-dom';

import {
  COMPANY_PATH,
  SETTINGS_PATH,
  SWEETHEART_PATH,
  OPPORTUNITIES_PATH,
  REPORTS_PATH,
  MESSAGES_PATH_FULL,
  CONTENT_MANAGEMENT_PATH,
  ANNOUNCEMENT_PATH,
} from 'constants/paths';

const useShowNavbarOnRoute = () => {
  const routeMatchCompany = useMatch(COMPANY_PATH);
  const routeMatchOpportunities = useMatch(OPPORTUNITIES_PATH);
  const routeMatchSettings = useMatch(SETTINGS_PATH);
  const routeMatchSweetheartList = useMatch(SWEETHEART_PATH);
  const routeMatchMessages = useMatch(MESSAGES_PATH_FULL);
  const routeMatchContent = useMatch(CONTENT_MANAGEMENT_PATH);
  const routeMatchReports = useMatch(REPORTS_PATH);
  const routeMatchAnnouncement = useMatch(ANNOUNCEMENT_PATH);

  const showNavbarOnRoute =
    routeMatchCompany ||
    routeMatchOpportunities ||
    routeMatchSettings ||
    routeMatchSweetheartList ||
    routeMatchContent ||
    routeMatchReports ||
    routeMatchMessages ||
    routeMatchAnnouncement;

  return [showNavbarOnRoute, routeMatchCompany];
};

export default useShowNavbarOnRoute;
