import PropTypes from 'prop-types';
import React from 'react';

import { InboxIcon } from '@heroicons/react/solid';
import { generatePath } from 'react-router-dom';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import intents from 'constants/intents';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import threadSource from 'constants/threadSource';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCreateThread from 'hooks/useCreateThread';
import { useTracking } from 'modules/segment/TrackingProvider';

import SweetheartListJournalistAdd from '../SweetheartListJournalist/SweetheartListJournalistAdd';
import { createSuccessMessage } from '../SweetheartListJournalist/SweetheartListJournalistReachOut';

import StoryHeading from './StoryHeading';
import StorySummary from './StorySummary';
import StoryTitle from './StoryTitle';

const SweetheartListNewsModal = ({ open, onClose, story }) => {
  const { id, image, title, summary, link, journalist } = story;
  const { createThread } = useCreateThread();
  const { companyData } = useCompanyData();
  const { showSuccess, showError } = useNotifications();
  const trackingService = useTracking();

  const companyId = companyData.uid;

  const sendColdOutreach = async (journalistData) => {
    if (!journalistData.id) {
      return;
    }
    try {
      const newThread = await createThread({
        reference: `stories/${id}`,
        journalistId: journalistData.id,
        source: threadSource.SWEETHEART_LIST,
        intent: intents.sweetheartList.OUTREACH,
      });

      if (!newThread.id) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "The thread couldn't be created.",
        });
        return;
      }

      showSuccess({
        title: 'Conversation started',
        Icon: InboxIcon,
        message: createSuccessMessage(
          generatePath(MESSAGES_PATH_FULL, {
            id: companyData.slug,
            threadId: newThread.id,
          })
        ),
      });

      trackingService.track('Thread Created', {
        source: 'sweetheartList',
        outreachType: intents.sweetheartList.OUTREACH,
        threadId: newThread.id,
        companyId,
      });
    } catch (error) {
      showError({ message: 'Error starting conversation' });
    }
  };

  return (
    <Modal open={open} onClose={onClose} widthClass="max-w-2xl">
      <Modal.Close srMessage="Close story modal" onClose={onClose} />

      <Modal.Content>
        <div className="full-width">
          {image && (
            <img
              src={image}
              alt={`Cover for ${title}`}
              className="w-full object-cover rounded-t-lg"
            />
          )}
          <div className="mt-2">
            <StoryHeading {...story} />
            <StoryTitle title={title} />
            <StorySummary summary={summary} />
          </div>
          <div className="flex justify-center p-4">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex text-sm justify-center items-center py-2 px-3 border shadow-sm font-medium rounded-md whitespace-nowrap focus:outline-none focus:ring bg-teal-500 hover:bg-teal-400 focus:border-teal-700 text-white"
            >
              Read More
            </a>
          </div>
        </div>
      </Modal.Content>
      <Modal.LeftButtons>
        <div>
          <SweetheartListJournalistAdd
            placeholder="Add journalist to list"
            journalistId={journalist?.id}
          />
        </div>
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <Button
          onClick={() => {
            onClose(false);
            sendColdOutreach(journalist);
          }}
          data-test-id="opportunity-responseModal-form-submit"
          data-intercom-id="opportunity-responseModal-submit"
        >
          Create Draft
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListNewsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  story: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    journalist: PropTypes.object,
  }),
};

export default SweetheartListNewsModal;
