import {
  BeakerIcon,
  CashIcon,
  ChartBarIcon,
  CubeTransparentIcon,
  DocumentAddIcon,
  LightBulbIcon,
  NewspaperIcon,
  PencilAltIcon,
  SpeakerphoneIcon,
  TrendingUpIcon,
  UserAddIcon,
} from '@heroicons/react/solid';

import { ReactComponent as MergeIcon } from 'static/icon-merge.svg';

export const resourceStatus = {
  TODO: 'todo',
  DRAFTING: 'drafting',
  COMPLETED: 'completed',
};

const resourceTypes = {
  QA: 'q-a',
  WHITEPAPER: 'whitepaper',
  MESSAGING: 'messaging',
  QUOTES: 'quotes',
  PRODUCTINFO: 'product-info',
  SALES: 'sales',
  INVESTOR: 'investor-fundraising',
};

const docuemntTypes = {
  LINKEDIN: 'linkedIn',
  TWITTER: 'twitter',
  NEWSLETTER: 'newsletter',
};

export const announcementTypes = {
  FUNDING: 'funding-announcement',
  PARTNERSHIP: 'partnership-announcement',
  PRODUCT: 'product-announcement',
  HIRING: 'hiring-announcement',
  MARKET: 'market-announcement',
  MA: 'ma-announcement',
  BRAND: 'brand-announcement',
  IPO: 'ipo-announcement',
};

export const contentManagementTypes = {
  ARTICLE: 'article',
  ARTICLEIDEA: 'article-idea',
  BLOG: 'blog',
  ...announcementTypes,
  ...docuemntTypes,
  ...resourceTypes,
};

export const documentTypes = [
  {
    id: 8010,
    name: contentManagementTypes.ARTICLE,
    Icon: NewspaperIcon,
    label: 'Article',
    description:
      'Share your expertise with an idea for a thought leadership article',
  },
  {
    id: 8011,
    name: contentManagementTypes.ARTICLEIDEA,
    Icon: LightBulbIcon,
    label: 'Article Idea',
    description:
      'Propose a groundbreaking idea for a thought leadership article.',
  },
  {
    id: 8012,
    name: contentManagementTypes.FUNDING,
    Icon: CashIcon,
    label: 'Funding Announcement',
    description:
      'Broadcast your latest funding round and share your growth plans.',
  },
  {
    id: 8013,
    name: contentManagementTypes.PARTNERSHIP,
    Icon: BeakerIcon,
    label: 'Partnership Announcement',
    description: 'Celebrate new collaborations and strategic partnerships.',
  },
  {
    id: 8014,
    name: contentManagementTypes.PRODUCT,
    Icon: CubeTransparentIcon,
    label: 'Product Announcement',
    description: 'Introduce your latest innovations and product developments.',
  },
  {
    id: 8015,
    name: contentManagementTypes.HIRING,
    Icon: UserAddIcon,
    label: 'Hiring Announcement',
    description:
      'Announce key hires and how they will contribute to your vision.',
  },
  {
    id: 8016,
    name: contentManagementTypes.MARKET,
    Icon: TrendingUpIcon,
    label: 'Market Announcement',
    description: 'Share significant market achievements and milestones.',
  },
  {
    id: 8017,
    name: contentManagementTypes.MA,
    Icon: MergeIcon,
    label: 'M&A Announcement',
    description: 'Detail your merger or acquisition and the future it heralds.',
  },
  {
    id: 8018,
    name: contentManagementTypes.BRAND,
    Icon: SpeakerphoneIcon,
    label: 'Brand Announcement',
    description: 'Unveil a brand evolution or rebranding initiative.',
  },
  {
    id: 8019,
    name: contentManagementTypes.IPO,
    Icon: ChartBarIcon,
    label: 'IPO Announcement',
    description:
      'Announce your initial public offering and what investors can expect.',
  },
  {
    id: 8020,
    name: contentManagementTypes.BLOG,
    Icon: PencilAltIcon,
    label: 'Blog',
    description: 'Share insights and stories on your official blog.',
  },
  {
    id: 8021,
    name: 'blank',
    Icon: DocumentAddIcon,
    label: 'Blank',
  },
];

export const bgColors = {
  // resource status
  todo: 'red-100',
  completed: 'green-100',
  drafting: 'gray-100',

  // documents types
  article: 'green-100',
  'article-idea': 'purple-100',
  'funding-announcement': 'blue-100',
  'partnership-announcement': 'indigo-100',
  'product-announcement': 'pink-100',
  'hiring-announcement': 'fuchsia-100',
  'market-announcement': 'lime-100',
  'ma-announcement': 'teal-100',
  'brand-announcement': 'amber-100',
  'ipo-announcement': 'orange-100',
  blog: 'red-100',
  linkedIn: 'blue-100',
  twitter: 'blue-100',
  newsletter: 'gray-100',
};

export const textColors = {
  // resource status
  todo: 'red-700',
  completed: 'green-700',
  drafting: 'gray-700',

  // documents types
  article: 'green-500',
  'article-idea': 'purple-500',
  'funding-announcement': 'blue-500',
  'partnership-announcement': 'indigo-500',
  'product-announcement': 'pink-500',
  'hiring-announcement': 'fuchsia-500',
  'market-announcement': 'lime-500',
  'ma-announcement': 'teal-500',
  'brand-announcement': 'amber-500',
  'ipo-announcement': 'orange-500',
  blog: 'red-500',
  linkedIn: 'blue-500',
  twitter: 'blue-500',
  newsletter: 'gray-500',
};

export const getDocumentType = (content) =>
  documentTypes.find((tag) => tag.name === content.type) || null;

export default contentManagementTypes;
