import PropTypes from 'prop-types';
import React from 'react';

import JournalistTeaser from 'components/JournalistTeaser';

const AnnouncementJournalistListItem = ({
  journalist,
  onCheckboxChange,
  isJournalistSelected,
}) => (
  <div>
    {journalist && (
      <div
        key={journalist.id}
        className="flex items-center justify-between p-2"
      >
        <div
          className={`flex-grow truncate ${
            journalist?.publication?.image && 'pb-2'
          }`}
        >
          <JournalistTeaser journalist={journalist} />
        </div>

        <input
          type="checkbox"
          className="h-4 w-4 ml-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
          checked={isJournalistSelected}
          onChange={(e) => {
            onCheckboxChange(journalist, e.target.checked);
          }}
        />
      </div>
    )}
  </div>
);

AnnouncementJournalistListItem.propTypes = {
  journalist: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  isJournalistSelected: PropTypes.bool.isRequired,
};

export default AnnouncementJournalistListItem;
