import React, { useState } from 'react';

import { TrashIcon, PencilIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import { getAnnouncementType } from 'constants/announcementTypes';
import { CONTENT_MANAGEMENT_PATH, SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import SweetheartListProvider from 'contexts/SweetheartListContext/SweetheartListContext';
import useAPIRequest from 'hooks/useAPIRequest';
import getPageTitle from 'utils/getPageTitle';

import ContentPlanAnnouncementModal from '../ContentPlan/ContentPlanAnnouncementModal';

import AnnouncementAttachListModal from './AnnouncementAttachListModal';
import AnnouncementDocumentPreview from './AnnouncementDocumentPreview';
import AnnouncementJournalistList from './AnnouncementJournalistList';

const AnnouncementPage = () => {
  const { companyData } = useCompanyData();
  const navigate = useNavigate();
  const { announcementId } = useParams();
  const [isAttachListModalOpen, setIsAttachListModalOpen] = useState(false);
  const [currentListId, setCurrentListId] = useState(null);
  const [isAnnouncementModalOpen, setIsAnnouncementModalOpen] = useState(false);

  const {
    data: announcement,
    loading: isAnnouncementLoading,
    fetchData: fetchMilestone,
  } = useAPIRequest({
    service: 'MOD',
    endpoint: `/milestones/${companyData.uid}/announcements/${announcementId}`,
    method: 'GET',
    initialData: null,
  });

  const { fetchData: upsertAnnouncement } = useAPIRequest({
    service: 'MOD',
    endpoint: `/milestones/${companyData.uid}/announcements`,
    method: 'PUT',
  });

  const { data: journalistLists, loading: isLoading } = useAPIRequest({
    service: 'JPD',
    endpoint: `/companies/${companyData.uid}/journalistLists`,
    method: 'GET',
    initialData: [],
  });

  const announcementType = getAnnouncementType(
    announcement?.document?.type
  )?.name;

  const handleTitleEdit = () => setIsAnnouncementModalOpen(true);

  const handleAttachList = () => setIsAttachListModalOpen(true);

  const handleAnnouncementSubmit = async (data) => {
    const startTime = moment(
      `${data.startTime.day} ${data.startTime.month} ${data.startTime.year}`,
      'D MMMM YYYY'
    ).utcOffset(0, true);

    const announcementData = {
      id: announcement.id,
      documentId: announcement.documentId,
      title: data.title,
      type: data.type,
      startTime,
    };

    await upsertAnnouncement({
      body: { ...announcementData },
    });

    setIsAnnouncementModalOpen(false);
    await fetchMilestone();
  };

  const handleSelectAttachList = async (list) => {
    const updatedList = [...(announcement.journalistLists || []), { ...list }];
    await upsertAnnouncement({
      body: {
        ...announcement,
        journalistLists: updatedList.map((item) => item.id),
      },
    });

    setIsAttachListModalOpen(false);
    await fetchMilestone();
  };

  const handleRemoveList = async (listId) => {
    const updatedList = (announcement.journalistLists || []).filter(
      (item) => item.id !== listId
    );

    await upsertAnnouncement({
      body: {
        ...announcement,
        journalistLists: updatedList.map((item) => item.id),
      },
    });

    await fetchMilestone();
  };

  const handleEditDocument = () => {
    const path = generatePath(CONTENT_MANAGEMENT_PATH, {
      id: companyData.slug,
      tab: 'documents',
      documentId: announcement?.document?.id,
    });
    navigate(`${path}?from=announcement:${announcementId}`);
  };

  if (isAnnouncementLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{getPageTitle('Company Announcement')}</title>
      </Helmet>
      <SweetheartListProvider>
        <div className="flex flex-col xl:flex-row p-4">
          <div className="w-full xl:w-2/3 xl:pr-2">
            <div className="bg-white p-[11px] rounded-t-md shadow">
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-medium">
                  {announcement.document.title ?? announcement.title}
                </h1>
                <Button onClick={handleTitleEdit}>Edit</Button>
              </div>
            </div>
            <AnnouncementDocumentPreview
              documentId={announcement.document.id}
              document={announcement.document}
              onEdit={handleEditDocument}
            />
          </div>

          <div className="w-full xl:w-1/3 xl:pl-2">
            <div className="bg-white p-4 shadow rounded-t-md border-b">
              <h2 className="text-xl font-medium">Overview</h2>
            </div>
            <div className="bg-white p-4 mb-2 shadow rounded-b-md">
              <div className="flex items-center justify-between border rounded-md p-2 mb-2">
                <div className="flex-shrink-0">
                  <span className="font-medium whitespace-nowrap">
                    Announcement:
                  </span>
                </div>
                <div className="ml-2 flex-grow truncate">
                  {new Date(
                    announcement.document.lastEdited
                  ).toLocaleDateString('en-GB', {
                    timeZone: 'CET',
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                  })}
                </div>
                <Button
                  onClick={() => setIsAnnouncementModalOpen(true)}
                  className="ml-4 flex-shrink-0"
                >
                  Edit
                </Button>
              </div>
              <div className="flex items-center justify-between border rounded-md p-2">
                <div className="flex-shrink-0">
                  <span className="font-medium whitespace-nowrap">
                    News Type:
                  </span>
                </div>
                <div className="ml-2 flex-grow truncate">
                  {announcementType}
                </div>
                <Button
                  onClick={() => setIsAnnouncementModalOpen(true)}
                  className="ml-4 flex-shrink-0"
                >
                  Edit
                </Button>
              </div>
            </div>

            {announcement.journalistLists?.length > 0 && (
              <div className="mt-4">
                {announcement.journalistLists.map((journalistList) => (
                  <div key={journalistList.id}>
                    <div className="flex flex-row justify-between bg-white p-4 shadow rounded-t-md border-b">
                      <span className="flex items-center font-medium">
                        {journalistList.name}
                      </span>

                      <div className="flex flex-row gap-1">
                        <Button
                          onClick={() =>
                            navigate(
                              generatePath(SWEETHEART_PATH, {
                                id: companyData.slug,
                                tab: 'lists',
                                listId: journalistList.id,
                              })
                            )
                          }
                        >
                          <PencilIcon className="w-4 h-4" />
                        </Button>
                        <Button
                          type="danger"
                          onClick={() => handleRemoveList(journalistList.id)}
                        >
                          <TrashIcon className="w-4 h-4" />
                        </Button>
                      </div>
                    </div>

                    <div className="bg-white p-4 mb-4 shadow rounded-b-md">
                      <AnnouncementJournalistList
                        journalistsData={journalistList.journalists}
                        listId={journalistList.id}
                        currentListId={currentListId}
                        setCurrentListId={setCurrentListId}
                        document={announcement?.document}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <Button onClick={handleAttachList} className="w-full">
              + Attach List
            </Button>
            {isAttachListModalOpen && (
              <AnnouncementAttachListModal
                open={isAttachListModalOpen}
                onClose={() => setIsAttachListModalOpen(false)}
                onSelect={handleSelectAttachList}
                journalistLists={journalistLists.map((list) => ({
                  ...list,
                  isListAlreadySelected: announcement.journalistLists.some(
                    (selectedList) => selectedList.id === list.id
                  ),
                }))}
                isJournalistListsLoading={isLoading}
              />
            )}
          </div>

          {isAnnouncementModalOpen && (
            <ContentPlanAnnouncementModal
              open={isAnnouncementModalOpen}
              onSubmit={handleAnnouncementSubmit}
              onClose={() => setIsAnnouncementModalOpen(false)}
              announcement={announcement}
            />
          )}
        </div>
      </SweetheartListProvider>
    </PageWrapper>
  );
};

export default AnnouncementPage;
