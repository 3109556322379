import React, { useState } from 'react';

import { UserAddIcon } from '@heroicons/react/solid';
import { Navigate, generatePath, useParams } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import { allLanguages } from 'constants/defaultLanguages';
import { tabNames } from 'constants/journalistsTabs';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import JournalistsSearchTable from './JournalistSearchTable';
import SweetheartListJournalistsSearchControls from './SweetheartListJournalistsSearchControls';

const SweetheartListJournalistsSearch = () => {
  const [terms, setTerms] = useState([]);
  const [language, setLanguage] = useState(allLanguages.value);

  const { journalists, isLoadingJournalists } = useSweetheartListContext();
  const { companyData } = useCompanyData();
  const { slug } = companyData;

  const { tab, id: companyIdInUrl } = useParams();

  if (tab !== tabNames.SEARCH) {
    return null;
  }

  if (companyIdInUrl !== slug) {
    return (
      <Navigate
        to={generatePath(SWEETHEART_PATH, {
          id: slug,
          tab: tabNames.SEARCH,
        })}
        replace
      />
    );
  }

  return (
    <div className="relative grow">
      <SweetheartListJournalistsSearchControls
        terms={terms}
        setTerms={setTerms}
        language={language}
        setLanguage={setLanguage}
      />

      <div className="relative">
        {journalists?.length > 0 && (
          <JournalistsSearchTable
            journalists={journalists}
            isLoading={isLoadingJournalists}
            hideColumns={['activity', 'reachout', 'delete']}
          />
        )}
      </div>

      {!isLoadingJournalists && !terms.length ? (
        <EmptyState
          title="Search for journalists"
          subtitle="Add any number of search terms to find journalists that match all terms"
          Icon={UserAddIcon}
        />
      ) : null}
    </div>
  );
};

export default SweetheartListJournalistsSearch;
