/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import noop from 'lodash/noop';
import { Link, generatePath } from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useJournalistThreadActivity from 'hooks/useJournalistThreadActivity';

import SweetheartListJournalistAdd from './SweetheartListJournalistAdd';
import SweetheartListJournalistContacted from './SweetheartListJournalistContacted';
import SweetheartListJournalistDelete from './SweetheartListJournalistDelete';
import SweetheartListJournalistProfile from './SweetheartListJournalistProfile';
import SweetheartListJournalistReachOut from './SweetheartListJournalistReachOut';
import SweetheartListJournalistTier from './SweetheartListJournalistTier';

const SweetheartListSearchJournalist = ({
  journalist = {},
  onClick = noop,
  hideColumns = [],
}) => {
  const { companyData } = useCompanyData();
  const currentJournalistThreads = useJournalistThreadActivity(journalist.id);

  const mostRecentActiveOrDraftThread =
    useMemo(
      () => currentJournalistThreads.find((thread) => !thread.isArchived),
      [currentJournalistThreads]
    ) ?? {};

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <tr
      className="odd:bg-white even:bg-gray-50 odd:hover:bg-gray-50 even:hover:bg-gray-100 cursor-pointer"
      data-test-id="sweetheartList-table-row"
      onClick={onClick}
    >
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist?.name}
          url={journalist?.image}
          hasStats={
            journalist.hasStats || !!journalist?.journalistAnalyticsIngest
          }
          ImageComponent={JournalistImage}
          testId="sweetheartList-table-row-journalist"
        />
      </td>
      <td className="px-4 py-2">{journalist?.jobTitle || 'n/a'}</td>
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist?.publication?.name}
          url={journalist?.publication?.image}
          ImageComponent={PublicationImage}
          testId="sweetheartList-table-row-publication"
        />
      </td>
      <td className="px-4 py-2 text-center">
        <SweetheartListJournalistTier tier={journalist?.publication?.tier} />
      </td>
      <td className="px-4 py-2 text-center">
        <DomainAuthorityPill
          domainAuthority={journalist?.publication?.domainAuthority}
        />
      </td>

      {!hideColumns.includes('activity') ? (
        <td className="px-4 py-2 text-left">
          {mostRecentActiveOrDraftThread.id ? (
            <Link
              key={mostRecentActiveOrDraftThread.id}
              onClick={handleClick}
              to={generatePath(MESSAGES_PATH_FULL, {
                id: companyData.slug,
                threadId: mostRecentActiveOrDraftThread.id,
              })}
              className="cursor-pointer block"
            >
              <SweetheartListJournalistContacted
                journalist={journalist}
                isActiveConversation={
                  mostRecentActiveOrDraftThread.isActiveConversation
                }
                date={
                  new Date(mostRecentActiveOrDraftThread.dateCreated)
                    .toISOString()
                    .split('T')[0]
                }
              />
            </Link>
          ) : null}
        </td>
      ) : null}

      {!hideColumns.includes('reachout') ? (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistReachOut journalist={journalist} />
        </td>
      ) : null}

      {!hideColumns.includes('delete') ? (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistDelete journalistId={journalist.id} />
        </td>
      ) : null}

      {!hideColumns.includes('add') ? (
        <td className="px-4 py-2 text-center">
          <SweetheartListJournalistAdd journalistId={journalist.id} />
        </td>
      ) : null}
    </tr>
  );
};

SweetheartListSearchJournalist.propTypes = {
  journalist: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  hideColumns: PropTypes.array,
};

export default SweetheartListSearchJournalist;
