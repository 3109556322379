import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

import JournalistsBulkActions from 'routes/SweetheartList/JournalistsBulkActions';
import SweetheartListJournalistListsListBulkConversationsModal from 'routes/SweetheartList/SweetheartListJournalistListsListBulkConversationsModal';

import AnnouncementJournalistListItem from './AnnouncementJournalistListItem';

const AnnouncementJournalistList = ({
  journalistsData = [],
  listId = '',
  currentListId,
  setCurrentListId,
  document = {},
}) => {
  const [selectedJournalists, setSelectedJournalists] = useState([]);
  const [isBulkConversationsModalOpen, setIsBulkConversationsModalOpen] =
    useState(false);
  const [isListVisible, setIsListVisible] = useState(true);

  const isJournalistSelected = (journalistId) =>
    selectedJournalists.some((journalist) => journalist.id === journalistId);

  const handleCheckboxChange = (journalist, isChecked) => {
    if (isChecked) {
      setCurrentListId(listId);
      setSelectedJournalists((prev) => [
        ...prev,
        { id: journalist.id, ...journalist },
      ]);
    } else {
      setSelectedJournalists((prev) =>
        prev.filter((item) => item.id !== journalist.id)
      );
    }
  };

  useEffect(() => {
    if (currentListId !== listId) {
      setSelectedJournalists([]);
    }
  }, [currentListId, listId]);

  return (
    <div>
      <div
        className="flex flex-row justify-between bg-white p-4 shadow rounded-t-md border-b mb-2 cursor-pointer"
        onClick={() => setIsListVisible(!isListVisible)}
      >
        <span className="flex items-center font-medium">All Journalists</span>
        <div className="flex flex-row font-medium">
          <span>{journalistsData.length} Journalists</span>
          <div className="flex items-center justify-center">
            {isListVisible ? (
              <ChevronUpIcon className="w-5 h-5 text-teal-500 ml-2" />
            ) : (
              <ChevronDownIcon className="w-5 h-5 text-teal-500 ml-2" />
            )}
          </div>
        </div>
      </div>

      {isListVisible && (
        <div>
          {journalistsData.map((journalist) => (
            <div key={journalist.id} className="border rounded-md mb-2">
              <AnnouncementJournalistListItem
                journalist={journalist}
                onCheckboxChange={handleCheckboxChange}
                isJournalistSelected={isJournalistSelected(journalist.id)}
              />
            </div>
          ))}
        </div>
      )}

      {selectedJournalists.length ? (
        <JournalistsBulkActions
          selected={selectedJournalists}
          createDrafts={() => setIsBulkConversationsModalOpen(true)}
        />
      ) : null}

      {isBulkConversationsModalOpen && (
        <SweetheartListJournalistListsListBulkConversationsModal
          onCancel={() => setIsBulkConversationsModalOpen(false)}
          selected={selectedJournalists}
          draftContent={document.draft.content}
        />
      )}
    </div>
  );
};

AnnouncementJournalistList.propTypes = {
  journalistsData: PropTypes.array.isRequired,
  listId: PropTypes.string.isRequired,
  currentListId: PropTypes.string.isRequired,
  setCurrentListId: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
};

export default AnnouncementJournalistList;
