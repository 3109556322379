import React from 'react';

import { XIcon } from '@heroicons/react/outline';
import { sortBy, uniqBy } from 'lodash';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';
import useCompanies from 'hooks/useCompanies';
import useAgencyCompanies from 'routes/Agency/hooks/useAgencyCompanies';

const ImpersonateDropdown = () => {
  const companies = useCompanies();
  const { ownCompanySlug, claimsAdmin, claimsAgencyId } = useUserRoles();
  const { impersonatedCompanySlug, setImpersonatedCompanySlug } =
    useImpersonation();

  const { data } = useAgencyCompanies(claimsAdmin ? null : claimsAgencyId);
  const allCompanies = sortBy(
    uniqBy(
      data.items
        .concat(claimsAdmin ? companies : [])
        .filter((c) => c.slug && c.name),
      'slug'
    )
  );

  return (
    <div className="border-b pb-4 mb-2">
      <div className="flex gap-2 items-center">
        <InputFieldDropdown
          options={[
            {
              label: 'Select company',
              value: null,
            },
            ...allCompanies.map((company) => ({
              label: company.name,
              value: company.slug,
            })),
          ]}
          onChange={setImpersonatedCompanySlug}
          value={impersonatedCompanySlug}
          wrapperClassName="grow max-w-[calc(100%-28px)]"
          buttonClassName="w-full"
          optionsClassName="z-50"
        />
        <button
          type="button"
          className="text-teal-600 hover:text-teal-800 "
          onClick={() => setImpersonatedCompanySlug(ownCompanySlug)}
        >
          <XIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default ImpersonateDropdown;
