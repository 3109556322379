import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import CompanyImage from 'components/company/CompanyImage';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useCompanies from 'hooks/useCompaniesV2';

const AdminOpportunitiesCompanySelectModal = ({
  onClose = noop,
  selectedCompanies = [],
  onSelect = noop,
}) => {
  const [companyIds, setCompanyIds] = useState(selectedCompanies);
  const { data: companies, isLoading } = useCompanies();
  const [companyQuery, setCompanyQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const matchingCompanies = useMemo(
    () =>
      (companies || []).filter((c) =>
        c.name?.toLowerCase()?.includes(companyQuery?.toLowerCase())
      ),
    [companies, companyQuery]
  );

  const toggleCompany = (id) => {
    if (companyIds.includes(id)) {
      setCompanyIds((prev) => prev.filter((companyId) => companyId !== id));
    } else {
      setCompanyIds((prev) => [...prev, id]);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Modal open>
      <Modal.Title>Add targeted company</Modal.Title>
      <Modal.Content className="flex flex-col gap-2">
        <div className="relative">
          <InputFieldText
            name="companyQuery"
            onChange={(e) => setCompanyQuery(e.target.value)}
            placeholder="Search for a company"
          >
            <InputFieldText.Overlay className="flex justify-end items-center pr-2">
              <SearchIcon className="w-4 h-4 text-gray-400 right-2" />
            </InputFieldText.Overlay>
          </InputFieldText>
        </div>
        <div className="max-h-[60vh] overflow-y-scroll gap-2 flex flex-col">
          {matchingCompanies?.map((company) => (
            <div
              key={company.id}
              className={classNames(
                'flex items-center gap-2 text-gray-700 p-2 border border-gray-300 rounded-md',
                {
                  'border-teal-500': companyIds.includes(company.id),
                }
              )}
            >
              <CompanyImage image={company.logo} alt={`${company.name} logo`} />
              <div className="flex flex-col w-fit">
                <span className="font-semibold">{company.name}</span>
                <span className="w-fit lowercase bg-gray-200 px-2 py-0.5 rounded-md text-xs">
                  {company?.website || 'no website'}
                </span>
              </div>
              <Button
                type={companyIds.includes(company.id) ? 'primary' : 'secondary'}
                className="ml-auto"
                onClick={() => toggleCompany(company.id)}
              >
                {companyIds.includes(company.id) ? 'Selected' : 'Select'}
              </Button>
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.LeftButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <Button
          className="relative"
          onClick={async () => {
            setLoading(true);
            await onSelect(companyIds);
            setLoading(false);
          }}
        >
          {loading && <Spinner />}
          Save
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminOpportunitiesCompanySelectModal.propTypes = {
  onClose: PropTypes.func,
  selectedCompanies: PropTypes.array,
  onSelect: PropTypes.func,
};

export default AdminOpportunitiesCompanySelectModal;
