import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { SparklesIcon, ThumbDownIcon } from '@heroicons/react/solid';
import flow from 'lodash/flow';
import noop from 'lodash/noop';

import useCompaniesOpportunitiesOps from 'hooks/useCompaniesOpportunitiesOps';

import useQueryParams from '../hooks/useQueryParams';

const OpportunitiesListBulkActions = ({
  selectedOpportunities = [],
  setSelectedOpportunities = noop,
}) => {
  const { showDeclined } = useQueryParams();
  const companiesOpportunitiesOps = useCompaniesOpportunitiesOps();

  const createBulkActionFlow = (action) =>
    flow([
      (e) => e.preventDefault(),
      () => companiesOpportunitiesOps[action],
      (bulkAction) => bulkAction(selectedOpportunities),
      () => setSelectedOpportunities([]),
    ]);

  useEffect(
    () => () => setSelectedOpportunities([]),
    [setSelectedOpportunities, showDeclined]
  );

  return (
    <div className="hidden lg:flex fixed w-full left-0 pl-28 z-50 bottom-16 justify-center items-center text-sm">
      <div className="bg-teal-500 text-white rounded-xl flex items-center divide-x">
        <p className="px-4 py-2">
          {selectedOpportunities.length} items selected
        </p>
        <button
          type="button"
          onClick={createBulkActionFlow('bulkRespond')}
          className="px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer font-medium"
          data-test-id="opportunities-bulkActions-respond"
        >
          <SparklesIcon className="w-4" />
          <span>Respond</span>
        </button>
        <button
          type="button"
          onClick={createBulkActionFlow('bulkDecline')}
          className="px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer rounded-r-xl font-medium"
          data-test-id="opportunities-bulkActions-decline"
        >
          <ThumbDownIcon className="w-4" />
          <span>Decline</span>
        </button>
      </div>
    </div>
  );
};

OpportunitiesListBulkActions.propTypes = {
  selectedOpportunities: PropTypes.array.isRequired,
  setSelectedOpportunities: PropTypes.func.isRequired,
};

export default OpportunitiesListBulkActions;
