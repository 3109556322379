import React from 'react';

import InputFieldTagsAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocompleteSuggestions';

import useSearchTerms from '../hooks/useSearchTerms';

const OpportunitiesControlsSearch = () => {
  const { terms, addTerm, removeTerm } = useSearchTerms();

  return (
    <div
      className="md:py-2 relative flex-1 xl:max-w-[50vw]"
      data-intercom-id="opportunities-search"
    >
      <InputFieldTagsAutocompleteSuggestions
        terms={terms}
        addTerm={addTerm}
        removeTerm={removeTerm}
        showHowTo
        testId="opportunities-search"
      />
    </div>
  );
};

export default OpportunitiesControlsSearch;
