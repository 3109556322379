import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import { tabNames } from 'constants/journalistsTabs';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import StoryCard from './StoryCard';
import SweetheartListNewsModal from './SweetheartListNewsModal';
import useJournalistsFromStories from './hooks/useJournalistsFromStories';

const SweetheartListNews = () => {
  const { isLoadingJournalistsStories, journalistsStories } =
    useSweetheartListContext();
  const { stories } = journalistsStories;
  const { isLoadingJournalistsData, journalistsData } =
    useJournalistsFromStories(stories);

  const { listId, tab } = useParams();
  const [selectedStory, setSelectedStory] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const storiesWithJournalistDetails = useMemo(() => {
    if (!stories?.length || !journalistsData?.length) return [];

    const journalistDataMap = new Map(
      journalistsData.map((journalist) => [journalist.id, journalist])
    );

    return stories.map((story) => {
      const journalistDetails =
        journalistDataMap.get(story.journalist.id) || {};
      return {
        ...story,
        journalist: {
          ...story.journalist,
          ...journalistDetails,
        },
      };
    });
  }, [stories, journalistsData]);

  const isLoading = isLoadingJournalistsStories || isLoadingJournalistsData;

  const shouldShowEmptyState = !isLoading && stories.length === 0;

  if (listId || tab !== tabNames.NEWS) {
    return null;
  }

  const handleCardClick = (story) => {
    setSelectedStory(story);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedStory(null);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {shouldShowEmptyState ? (
        <EmptyState
          testId="empty-state-no-stories"
          title="No Stories Available"
          subtitle="Currently, there are no stories to display. Please check back later."
        />
      ) : (
        <ul
          data-test-id="journalistLists-lists"
          className="relative grid grid-cols-1 gap-6 py-6 pt-0 sm:grid-cols-1"
        >
          {storiesWithJournalistDetails?.map((story) => (
            <li key={story.id} className="col-span-1 mx-14">
              <StoryCard story={story} onClick={() => handleCardClick(story)} />
            </li>
          ))}
        </ul>
      )}

      {selectedStory && (
        <SweetheartListNewsModal
          open={isModalOpen}
          onClose={closeModal}
          story={selectedStory}
        />
      )}
    </>
  );
};

export default SweetheartListNews;
