import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';

import Button from 'components/buttons/Button';

const AnnouncementDocumentPreview = ({ document, onEdit }) => {
  const [hover, setHover] = useState(false);

  if (!document) {
    return (
      <div className="relative p-4 mb-4 rounded-b-md shadow">
        No document found
      </div>
    );
  }

  return (
    <div
      className="relative p-4 mb-4 rounded-b-md shadow"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <div className="absolute inset-0 bg-gray-300 bg-opacity-50 flex items-center justify-center z-10">
          <Button onClick={onEdit} className="px-6 py-3 text-lg">
            Edit Document
          </Button>
        </div>
      )}

      <div className="bg-white mx-auto p-4 shadow-lg relative z-0">
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          value={document?.draft?.content}
          init={{
            plugins: 'autoresize',
            menubar: false,
            toolbar: false,
            readonly: true,
            min_height: 500,
            autoresize_bottom_margin: 10,
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
          }}
          className="w-full"
        />
      </div>
    </div>
  );
};

AnnouncementDocumentPreview.propTypes = {
  onEdit: PropTypes.func,
  document: PropTypes.object,
};

export default AnnouncementDocumentPreview;
