import PropTypes from 'prop-types';
import React from 'react';

import { SparklesIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';

const AdminCoveragesBulkActions = ({
  selected = [],
  onCreateInvoice = noop,
}) => {
  const hasDifferentCompanies = uniqBy(selected, 'company.id').length > 1;
  const hasInvoice = selected.some((c) => c.invoice?.id);
  const isDisabled = !selected.length || hasDifferentCompanies || hasInvoice;
  return (
    <div className="flex flex-col gap-2 fixed w-full left-0 md:pl-64 z-50 bottom-16 justify-center items-center text-sm">
      {hasDifferentCompanies && (
        <Callout type="warning" wrapperClassName="!mt-0">
          Can&apos;t create invoice for different companies
        </Callout>
      )}
      {hasInvoice && (
        <Callout type="warning" wrapperClassName="!mt-0">
          Some items already have invoices
        </Callout>
      )}
      <div className="bg-teal-500 text-white rounded-xl flex items-center divide-x">
        <p className="px-4 py-2">{selected.length} items selected</p>
        <button
          disabled={isDisabled}
          type="button"
          onClick={onCreateInvoice}
          className={classNames(
            'px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer rounded-r-xl font-medium',
            {
              'opacity-50': isDisabled,
            }
          )}
          data-test-id="opportunities-bulkActions-respond"
        >
          <SparklesIcon className="w-4" />
          <span>Create Invoice</span>
        </button>
      </div>
    </div>
  );
};

AdminCoveragesBulkActions.propTypes = {
  selected: PropTypes.array,
  onCreateInvoice: PropTypes.func,
};

export default AdminCoveragesBulkActions;
