import React, { Suspense } from 'react';

import Spinner from 'components/Spinner';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyThreads from 'hooks/useCompanyThreads';
import useOpportunitiesBySearch from 'hooks/useOpportunitiesBySearch';

import OpportunitiesControls from './OpportunitiesControls/OpportunitiesControls';
import OpportunitiesList from './OpportunitiesList/OpportunitiesList';
import OpportunitiesMeta from './OpportunitiesMeta/OpportunitiesMeta';
import useQueryParams from './hooks/useQueryParams';
import useSearchTerms from './hooks/useSearchTerms';

const Opportunities = () => {
  const { companyData } = useCompanyData();
  const { data: threads } = useCompanyThreads(companyData?.uid);

  const { terms } = useSearchTerms();
  const queryParams = useQueryParams();
  const {
    data: opportunities,
    isLoading,
    isError,
  } = useOpportunitiesBySearch({
    searchTerms: terms,
    queryParams,
    companyId: companyData?.uid,
  });
  return (
    <div id="opportunities" className="flex flex-col grow">
      <OpportunitiesControls />
      <OpportunitiesMeta />
      <Suspense fallback={<Spinner />}>
        {isLoading ? (
          <Spinner />
        ) : (
          <OpportunitiesList
            companyId={companyData?.uid}
            threads={threads}
            opportunities={opportunities.items}
            error={isError}
          />
        )}
      </Suspense>
    </div>
  );
};

export default Opportunities;
